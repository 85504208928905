import { SET_INFO } from './types';

const initialState = {
  item: null,
};

export const infoReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_INFO:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
