import React, { useEffect } from 'react';
import { Layout, ConfigProvider, Row, Col } from 'antd';
import { getTheme, useTheme } from '../../resources/theme';
import ContentLayout from './layout/content-layout';
import FooterLayout from './layout/footer-layout';
import { useSelector } from 'react-redux';
import { TextLarge } from '../text';

const LayoutPage = () => {
  const { sidebarMode, globalTheme } = useTheme();
  const title = useSelector((state) => state.title.title);
  const profile = useSelector((state) => state.line.profile);

  useEffect(() => {
    document.title = title;
  }, [title]);

  return (
    <ConfigProvider theme={globalTheme}>
      <Layout
        className="site-layout"
        style={{
          minHeight: '100vh',
        }}
      >
        <Row style={{ backgroundColor: '#F7941D', padding: '20px 0', textAlign: 'center' }}>
          <Col span={4} />
          <Col span={16}>
            <TextLarge text={title} style={{ color: 'white' }} />
          </Col>
          <Col span={4}>
            {profile?.pictureUrl && (
              <img
                src={profile?.pictureUrl}
                alt="Profile"
                width="50"
                height="50"
                style={{ borderRadius: '50%', cursor: 'pointer' }}
              />
            )}
          </Col>
        </Row>
        <ContentLayout getTheme={getTheme} sidebarMode={sidebarMode} />
        <FooterLayout />
      </Layout>
    </ConfigProvider>
  );
};

export default LayoutPage;
