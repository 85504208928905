import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { ButtonTheme } from '../buttons';
import errorImg from '../../assets/images/something-went-wrong.png';
import { windowNavigateReplaceTo } from '../../utils/navigation';
import { ErrorBoundaryWrapper, ImageErrorWrapper } from './styled-components';

function ErrorBoundaryController({ children }) {
  return (
    <ErrorBoundary
      FallbackComponent={() => (
        <ErrorBoundaryWrapper>
          <div style={{ textAlignLast: 'center' }}>
            <ImageErrorWrapper src={errorImg} />
            <h3>เกิดข้อผิดพลาด</h3>
            <p>ไม่สามารถใช้งานได้ เนื่องจากเกิดข้อผิดพลาดบางอย่าง</p>
            <ButtonTheme
              useFor="BACK"
              title="กลับหน้าหลัก"
              onClick={() => windowNavigateReplaceTo({ pathname: '/' })}
            />
          </div>
        </ErrorBoundaryWrapper>
      )}
    >
      {children}
    </ErrorBoundary>
  );
}

export default ErrorBoundaryController;
