import styled from 'styled-components';

export const ErrorBoundaryWrapper = styled.div`
  height: 100vh;
  align-content: center;
`;

export const ImageErrorWrapper = styled.img`
  min-height: 100%;
  width: 30vw;
`;
