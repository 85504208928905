import { alert, loading, loadingClose, removeAlert } from '../../../redux/alert/action';
import store from '../../../redux/store';
import {
  ALERT_REQ,
  ALERT_CLOSE,
  ALERT_LOADING_CLOSE,
  ALERT_LOADING,
} from '../../../redux/alert/types';

export const openLoading = () => {
  store.dispatch(loading({ type: ALERT_LOADING }));
};

export const closeLoading = () => {
  store.dispatch(loadingClose({ type: ALERT_LOADING_CLOSE }));
};

export const openAlert = (obj) => {
  store.dispatch(alert({ type: ALERT_REQ, payload: obj }));
  store.dispatch(loadingClose({ type: ALERT_LOADING_CLOSE }));
};

export const closeAlert = () => {
  store.dispatch(removeAlert({ type: ALERT_CLOSE }));
};
