import React from 'react';
import { Layout } from 'antd';
const { Footer } = Layout;
import { VERSION } from '../../../../resources';

const FooterLayout = () => {
  return <Footer style={{ textAlign: 'center' }}>ZettaSoft @2023 version {VERSION}</Footer>;
};

export default FooterLayout;
