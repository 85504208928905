export const default_form_input = [
  {
    form_id: 1,
    label: 'ชื่อ-นามสกุล',
    type: 'INPUT',
    style: {
      marginRight: 50,
    },
    customerProps: {
      maxLength: 20,
    },
    className: 'customer-input',
  },
  {
    form_id: 1,
    label: 'เบอร์โทร',
    type: 'INPUT',
  },
];

export const ERROR_MESSAGES = {
  REQUIRED_FIELD: 'กรุณากรอก',
  COMPLETE_ALL_INPUT: 'กรุณากรอกข้อมูลให้ครบถ้วน',
  SERVICE_ERROR: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง',
  WRONG_FORMAT: 'รูปแบบข้อมูลไม่ถูกต้อง',
};

export const VERSION = process.env.REACT_APP_VERSION;
