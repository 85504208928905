export const FORM_INPUT = () => {
  return [
    {
      name: 'username',
      label: 'ชื่อผู้ใช้',
      type: 'INPUT',
      placeholder: 'กรอกชื่อผู้ใช้',
      span: 24,
      rules: {
        required: true,
      },
    },
    {
      name: 'password',
      label: 'รหัสผ่าน',
      type: 'INPUT_PASSWORD',
      placeholder: 'กรอกรหัสผ่าน',
      span: 24,
      rules: {
        required: true,
      },
    },
  ];
};
