//TODO add to Template
import React, { Fragment } from 'react';
import { useController } from 'react-hook-form';
import { RowComponent } from '../../../styles/global-style';
import { TextSmall, TextInputLabel } from '../../text/index.jsx';

export const TextLabel = ({ control, item }) => {
  const { name, defaultValue, label, dataCy, ...propsInput } = item;

  const { field } = useController({
    control,
    name,
    defaultValue,
  });
  const { value } = field;

  return (
    <Fragment>
      <RowComponent>
        {label && <TextInputLabel text={label} key={name} {...dataCy} />}
      </RowComponent>
      <TextSmall text={value} style={{ marginLeft: `20px`, color: `#585858` }} {...propsInput} />
    </Fragment>
  );
};
