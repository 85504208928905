import React, { Fragment, useState } from 'react';
import { CameraFilled, UploadOutlined, PictureFilled } from '@ant-design/icons';
import { useController } from 'react-hook-form';
import { Button, Upload, Modal, Row, Col } from 'antd';
import { Required, RowComponent } from '../../../styles/global-style';
import { TextXSMall, TextInputLabel } from '../../text';
import { renderTypeError } from '../index.jsx';

const UploadFilesTwo = ({ control, item, setValue, getValues }) => {
  const { rules, name, defaultValue, label, disabled, dataCy, ...propsInput } = item;
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [cameraStream, setCameraStream] = useState(null);
  const [showCamera, setShowCamera] = useState(false);

  const { field, fieldState } = useController({
    control,
    name,
    rules,
    defaultValue,
  });
  const { error } = fieldState;
  const { value } = field;

  const onChange = (event) => {
    const fileList = event.fileList.map((file) => ({
      ...file,
      status: 'done',
    }));

    setValue(name, fileList);
  };

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };

  const startCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      setCameraStream(stream);
      setShowCamera(true);
    } catch (err) {
      console.error('ไม่สามารถเข้าถึงกล้องได้:', err);
    }
  };

  const stopCamera = () => {
    if (cameraStream) {
      cameraStream.getTracks().forEach((track) => track.stop());
      setCameraStream(null);
    }
    setShowCamera(false);
  };

  const capturePhoto = () => {
    const video = document.querySelector('video');
    const canvas = document.createElement('canvas');
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    canvas.getContext('2d').drawImage(video, 0, 0);

    canvas.toBlob(async (blob) => {
      const file = new File([blob], `camera-capture-${Date.now()}.jpg`, { type: 'image/jpeg' });
      const base64 = await getBase64(file);

      const fileList = getValues(name) || [];
      const newFileList = [
        ...fileList,
        {
          uid: `-${fileList.length + 1}`,
          name: file.name,
          status: 'done',
          url: base64,
          originFileObj: file,
        },
      ];

      setValue(name, newFileList);
      stopCamera();
    }, 'image/jpeg');
  };

  const uploadStyle = {
    uploadBox: {
      width: '150px',
      height: '150px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      border: '1px dashed #d9d9d9',
      borderRadius: '8px',
      backgroundColor: '#fafafa',
      textAlign: 'center',
      cursor: 'pointer',
      transition: 'all 0.3s',
      margin: '0 auto',
      padding: '10px',
      '&:hover': {
        borderColor: '#1890ff',
        backgroundColor: '#f0f5ff',
      },
    },
    iconContainer: {
      fontSize: '36px',
      color: '#40a9ff',
      marginBottom: '8px',
    },
    text: {
      color: '#666',
      fontSize: '14px',
    },
  };

  return (
    <Fragment>
      <RowComponent>
        {label && <TextInputLabel text={label} />}
        {rules && rules.required && <Required>*</Required>}
      </RowComponent>

      <Row gutter={24}>
        <Col span={12}>
          <div
            style={uploadStyle.uploadBox}
            onClick={startCamera}
            className="hover:border-blue-400 hover:bg-blue-50"
          >
            <div style={uploadStyle.iconContainer}>
              <CameraFilled style={{ fontSize: '36px', color: '#40a9ff' }} />
            </div>
            <div style={uploadStyle.text}>ถ่ายภาพ</div>
            <div style={{ fontSize: '12px', color: '#999', marginTop: '4px' }}>
              ใช้กล้องถ่ายภาพโดยตรง
            </div>
          </div>
        </Col>

        <Col span={12} style={{ marginTop: '6%' }}>
          <Upload
            id={name}
            beforeUpload={() => false}
            listType="picture"
            disabled={disabled || false}
            maxCount={item.maxCount || 1}
            multiple={item.maxCount > 1 || false}
            accept={item.accept || 'image/*'}
            onPreview={handlePreview}
            fileList={getValues(name)}
            onChange={onChange}
            showUploadList={false}
            {...propsInput}
          >
            <div style={uploadStyle.uploadBox} className="hover:border-blue-400 hover:bg-blue-50">
              <div style={uploadStyle.iconContainer}>
                <PictureFilled style={{ fontSize: '36px', color: '#40a9ff' }} />
              </div>
              <div style={uploadStyle.text}>แนบไฟล์</div>
              <div style={{ fontSize: '12px', color: '#999', marginTop: '4px' }}>
                เลือกไฟล์จากอุปกรณ์
              </div>
            </div>
          </Upload>
        </Col>
      </Row>

      {value?.length > 0 && (
        <div style={uploadStyle.preview}>
          <Upload
            listType="picture-card"
            fileList={getValues(name)}
            onPreview={handlePreview}
            onChange={onChange}
            showUploadList={{ showPreviewIcon: true, showRemoveIcon: true }}
          />
        </div>
      )}

      <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
        <img alt="preview" style={{ width: '100%' }} src={previewImage} />
      </Modal>

      <Modal
        open={showCamera}
        title="ถ่ายภาพ"
        onCancel={stopCamera}
        width={800}
        footer={[
          <Button key="cancel" onClick={stopCamera}>
            ยกเลิก
          </Button>,
          <Button key="capture" type="primary" onClick={capturePhoto}>
            ถ่ายภาพ
          </Button>,
        ]}
      >
        <video
          autoPlay
          playsInline
          style={{ width: '100%', borderRadius: '8px' }}
          ref={(video) => {
            if (video && cameraStream) {
              video.srcObject = cameraStream;
            }
          }}
        />
      </Modal>

      {error && (
        <div style={{ marginTop: '8px' }}>
          <TextXSMall text={renderTypeError(item, error)} color="red" />
        </div>
      )}
    </Fragment>
  );
};

export const MemoizedUploadTwo = UploadFilesTwo;
