import { App } from 'antd';
import { useSelector } from 'react-redux';
import { closeAlert } from './hooks/useAlert';
import { delay } from '../../functions';

const Alert = () => {
  const { message, notification, modal } = App.useApp();
  const alert = useSelector((state) => state.alert);

  const delayRemoveAlert = () => {
    delay(3000);
    closeAlert();
  };

  const renderModel = () => {
    const { options } = alert;
    switch (options.model) {
      case 'notification':
        delayRemoveAlert();
        return notification[options.type]({ message: options.message });
      case 'message':
        delayRemoveAlert();
        return message[options.type](options.message);
      case 'modal':
        let content = {
          content: options.message,
          onCancel: () => {
            closeAlert();
            options.onFinish();
          },
          onOk: () => {
            closeAlert();
            options.onFinish();
          },
        };
        if (options.title) {
          content = { ...content, title: options.title };
        }
        return modal[options.type](content);
      default:
        delayRemoveAlert();
        notification.info({ message: '' });
    }
  };
  alert.open && renderModel();
};

export default Alert;
