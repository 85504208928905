import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { useSelector } from 'react-redux';

const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 50,
    }}
    spin
  />
);

const Loading = (props) => {
  const alert = useSelector((state) => state.alert);
  return (
    <div className="div-loading-center">
      <Spin className="loading-center" spinning={alert.loading} indicator={antIcon}>
        {props.children}
      </Spin>
    </div>
  );
};

export default Loading;
