import React, { Fragment, useState } from 'react';
import { useController } from 'react-hook-form';
import { Button, Input, Input as InputAntd, InputNumber as InputNumberAntD, Modal } from 'antd';
import { Required, RowComponent } from '../../../styles/global-style';
import { TextXSMall, TextInputLabel } from '../../text';
import { renderTypeError } from '../index.jsx';
import { EyeInvisibleOutlined, EyeTwoTone, QrcodeOutlined } from '@ant-design/icons';
import { QrReader } from 'react-qr-reader';
import './styles.css';

const InputLabel = ({ control, item, handleChange }) => {
  const {
    rules,
    name,
    defaultValue,
    label,
    placeholder,
    disabled,
    onEnter,
    inputType = 'text',
    inputStep = '0.01',
    dataCy,
    ...propsInput
  } = item;

  const { field, fieldState } = useController({
    control,
    name,
    rules,
    defaultValue,
  });
  const { error } = fieldState;
  const { value } = field;

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      const { value: v } = event.target;
      onEnter && onEnter(v, true);
    }
  };

  return (
    <Fragment key={name}>
      <RowComponent>
        {label && <TextInputLabel text={label} />}
        {rules && rules.required && <Required>*</Required>}
      </RowComponent>
      <InputAntd
        id={name}
        name={name}
        value={value}
        type={inputType}
        step={inputStep}
        disabled={disabled}
        onChange={(e) => handleChange(e, item)}
        onKeyDown={(e) => handleKeyDown(e)}
        placeholder={placeholder}
        autoComplete={'off'}
        {...dataCy}
        {...propsInput}
      />
      {error && <TextXSMall text={renderTypeError(item, error)} color="red" />}
    </Fragment>
  );
};

export const InputLabelQr = ({ control, item, handleChange }) => {
  const {
    rules,
    name,
    defaultValue,
    label,
    placeholder,
    disabled,
    onEnter,
    inputType = 'text',
    inputStep = '0.01',
    dataCy,
    ...propsInput
  } = item;

  const { field, fieldState } = useController({
    control,
    name,
    rules,
    defaultValue,
  });
  const { error } = fieldState;

  const [storeName, setStoreName] = useState('');
  const [isQrScannerVisible, setQrScannerVisible] = useState(false);

  const handleScan = (result) => {
    if (result?.text) {
      try {
        const storeData = JSON.parse(result.text);
        const { store_id, store_name } = storeData;

        field.onChange(store_id);
        setStoreName(store_name);

        if (handleChange) {
          const syntheticEvent = {
            target: {
              name: name,
              value: store_id,
            },
          };
          handleChange(syntheticEvent, item);
        }

        setQrScannerVisible(false);
      } catch (error) {}
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && onEnter) {
      onEnter(event.target.value, true);
    }
  };

  return (
    <Fragment key={name}>
      <RowComponent>
        {label && <TextInputLabel text={label} />}
        {rules?.required && <Required>*</Required>}
      </RowComponent>

      <div className="flex w-full">
        <Input
          id={name}
          name={name}
          value={storeName} // แสดง store_name แทน value
          type={inputType}
          step={inputStep}
          disabled={true}
          onChange={(e) => handleChange(e, item)}
          onKeyDown={handleKeyDown}
          placeholder={placeholder}
          autoComplete="off"
          className="flex-grow"
          style={{ width: 'calc(100% - 40px)' }}
          {...dataCy}
          {...propsInput}
        />
        <Button
          icon={<QrcodeOutlined />}
          onClick={() => setQrScannerVisible(true)}
          className="ml-2"
          style={{ width: '40px' }}
        />
      </div>

      {error && <TextXSMall text={renderTypeError(item, error)} color="red" />}

      <Modal
        title="สแกน QR Code"
        open={isQrScannerVisible}
        onCancel={() => setQrScannerVisible(false)}
        footer={null}
        width={400}
      >
        <div className="relative w-full">
          <QrReader
            onResult={handleScan}
            constraints={{
              facingMode: 'environment',
            }}
            className="w-full"
            videoStyle={{ width: '100%' }}
          />
          <div
            className="absolute inset-0 border-2 border-blue-500 rounded-lg pointer-events-none"
            style={{
              margin: '20%',
              backgroundColor: 'rgba(0, 0, 0, 0.1)',
            }}
          />
        </div>
      </Modal>
    </Fragment>
  );
};

export const InputArea = ({ control, item, handleChange }) => {
  const {
    rules,
    name,
    defaultValue,
    label,
    placeholder,
    disabled,
    onEnter,
    inputType = 'text',
    inputStep = '0.01',
    dataCy,
    ...propsInput
  } = item;

  const { field, fieldState } = useController({
    control,
    name,
    rules,
    defaultValue,
  });
  const { error } = fieldState;
  const { value } = field;

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      const { value: v } = event.target;
      onEnter && onEnter(v, true);
    }
  };

  return (
    <Fragment key={name}>
      <RowComponent>
        {label && <TextInputLabel text={label} />}
        {rules && rules.required && <Required>*</Required>}
      </RowComponent>
      <InputAntd.TextArea
        id={name}
        name={name}
        value={value}
        type={inputType}
        step={inputStep}
        disabled={disabled}
        onChange={(e) => handleChange(e, item)}
        onKeyDown={(e) => handleKeyDown(e)}
        placeholder={placeholder}
        autoComplete={'off'}
        {...dataCy}
        {...propsInput}
      />
      {error && <TextXSMall text={renderTypeError(item, error)} color="red" />}
    </Fragment>
  );
};

export const InputNumber = ({ control, item, handleChange }) => {
  const {
    rules,
    name,
    defaultValue,
    label,
    placeholder,
    disabled,
    onEnter,
    inputPrecision = 2,
    dataCy,
    ...propsInput
  } = item;

  const { field, fieldState } = useController({
    control,
    name,
    rules,
    defaultValue,
  });
  const { error } = fieldState;
  const { value } = field;

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      const { value: v } = event.target;
      onEnter && onEnter(v, true);
    }
  };

  return (
    <Fragment key={name}>
      <RowComponent>
        {label && <TextInputLabel text={label} />}
        {rules && rules.required && <Required>*</Required>}
      </RowComponent>
      <InputNumberAntD
        style={{ width: '100%', textAlign: 'right', ...item.style }}
        id={name}
        name={name}
        value={value}
        precision={inputPrecision}
        disabled={disabled}
        onChange={(e) => handleChange(e, item)}
        onKeyDown={(e) => handleKeyDown(e)}
        placeholder={placeholder}
        {...dataCy}
        {...propsInput}
      />
      {error && <TextXSMall text={renderTypeError(item, error)} color="red" />}
    </Fragment>
  );
};

export const MemoizedInputLabel = InputLabel; //memo(, compareRender);

const InputPassword = ({ control, item, handleChange }) => {
  const {
    rules,
    name,
    defaultValue,
    label,
    placeholder,
    disabled,
    onEnter,
    dataCy,
    ...propsInput
  } = item;

  const { field, fieldState } = useController({
    control,
    name,
    rules,
    defaultValue,
  });
  const { error } = fieldState;
  const { value } = field;

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      const { value: v } = event.target;
      onEnter && onEnter(v, true);
    }
  };

  return (
    <Fragment>
      <RowComponent>
        {label && <TextXSMall text={label} />}
        {rules && rules.required && <Required>*</Required>}
      </RowComponent>
      <InputAntd.Password
        iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
        id={name}
        name={name}
        value={value}
        disabled={disabled}
        onChange={(e) => handleChange(e, item)}
        onKeyDown={(e) => handleKeyDown(e)}
        placeholder={placeholder}
        autoComplete={'off'}
        {...dataCy}
        {...propsInput}
      />
      {error && <TextXSMall text={renderTypeError(item, error)} color="red" />}
    </Fragment>
  );
};

export const MemoizedInputPassword = InputPassword;
