import React, { useCallback, useState, useEffect } from 'react';
import { Input, Row, Col, message } from 'antd';
import { QrcodeOutlined } from '@ant-design/icons';
import { StyledContainer } from './styles';
import liff from '@line/liff';
import { useDispatch } from 'react-redux';
import { setTitle } from '../../../redux/title/action';
import { FORM_INPUT } from './forms';
import { ButtonTheme } from '../../../components/buttons';
import { useVerifyNumber } from './hooks/useVerifyNumber';
import { useForm } from 'react-hook-form';
import { setWarrantyData } from '../../../redux/warranty/actions';

export const Warranty = () => {
  const { reset, getValues } = useForm();
  const [warrantyCode, setWarrantyCode] = useState('');
  const dispatch = useDispatch();

  const { mutate: onCallTele } = useVerifyNumber({ reset, getValues });

  useEffect(() => {
    dispatch(setTitle('ลงทะเบียนรับประกันสินค้า'));
  }, [dispatch]);

  const onSearch = (warrantyNumberCode) => {
    onCallTele(
      { warrantyNumberCode },
      {
        onSuccess: (data) => {
          dispatch(setWarrantyData(data));
        },
      },
    );
  };

  const extractWarrantyCode = (url) => {
    const params = new URLSearchParams(url.split('?')[1]);
    return params.get('fw') || '';
  };

  const handleQRCodeScan = useCallback(async () => {
    try {
      const result = await liff.scanCodeV2();
      if (result?.value) {
        const code = extractWarrantyCode(result.value);
        if (code) {
          setWarrantyCode(code);
        } else {
          message.warning('Invalid QR Code format. Please scan a valid QR Code.');
        }
      }
    } catch (error) {
      message.error('QR Code scan failed. Please try again.');
    }
  }, []);

  return (
    <StyledContainer>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginBottom: '20px',
          textAlign: 'center',
        }}
      >
        <h2>QR Code</h2>
        <p>รับประกันสินค้า</p>
        <QrcodeOutlined
          style={{ fontSize: '100px', cursor: 'pointer' }}
          onClick={handleQRCodeScan}
        />
        <p>สแกน QR Code</p>
      </div>

      <Row justify="center">
        <Col span={16}>
          {FORM_INPUT().map((input) => (
            <Input
              key={input.name}
              placeholder={input.placeholder}
              value={warrantyCode}
              onChange={(e) => setWarrantyCode(e.target.value)}
              style={{ padding: '10px', marginBottom: '10px' }}
            />
          ))}
        </Col>
        <ButtonTheme useFor="SUBMIT" title="ยืนยัน" onClick={() => onSearch(warrantyCode)} />
      </Row>
    </StyledContainer>
  );
};
