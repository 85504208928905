import { convertYearInputToPayload } from '../../../functions/convert';

export const insertSuperMember = (value) => {
  const formData = new FormData();

  formData.append('invNo', value.invNo);
  formData.append('telephone', value.telephone);
  formData.append('promotionId', value.promotionId);
  formData.append('storeId', value.storeId);
  formData.append('invNo', value.invNo);
  formData.append('birthDate', convertYearInputToPayload(value.birthDate));

  if (value.invImage && value.invImage.length > 0) {
    const file = value.invImage[0];
    if (file.originFileObj) {
      formData.append('invImage', file.originFileObj);
    } else if (file.url) {
      formData.append('invImageUrl', file.url);
    }
  }

  return formData;
};

export const insertWarranty = (value) => {
  const formData = new FormData();

  formData.append('telephone', value.telephone);
  formData.append('fullName', value.fullName);
  formData.append('warrantyNumberCode', value.warrantyNumberCode);
  formData.append('mobileBrandId', value.mobileBrandId);
  formData.append('mobileModelId', value.mobileModelId);
  formData.append('productId', value.productId);
  formData.append('channelBuyId', value.channelBuyId);
  formData.append('customerAccountCode', value.customerAccountCode);

  if (value.invImage && value.invImage.length > 0) {
    const file = value.invImage[0].originFileObj || value.invImage[0];
    formData.append('invImage', file);
  }

  if (value.packageImage && value.packageImage.length > 0) {
    const file = value.packageImage[0].originFileObj || value.packageImage[0];
    formData.append('packageImage', file);
  }

  return formData;
};

export const InsertWarranty2 = (value) => {
  return {
    invImage: value.invImage ? value.invImage.originFileObj : null,
    packageImage: value.packageImage ? value.packageImage.originFileObj : null,
    telephone: value.telephone,
    fullName: value.fullName,
    warrantyNumberCode: value.warrantyNumberCode,
    mobileBrandId: value.mobileBrandId,
    mobileModelId: value.mobileModelId,
    productId: value.productId,
    channelBuyId: value.channelBuyId,
    customerAccountCode: value.customerAccountCode,
  };
};
