import React from 'react';
import {
  BsFillCloudArrowDownFill,
  BsSearch,
  BsFillPenFill,
  BsFillTrashFill,
  BsBookHalf,
  BsFillXCircleFill,
  BsSave2Fill,
  BsPrinterFill,
  BsFillCheckCircleFill,
  BsLockFill,
  BsArrowLeftCircleFill,
  BsFillPlusCircleFill,
  BsArrowClockwise,
  BsShieldFillCheck,
  BsPersonCheckFill,
  BsKanban,
  BsJournalBookmarkFill,
  BsLayoutSidebarInsetReverse,
} from 'react-icons/bs';
import { RiLogoutBoxRFill, RiLoginBoxFill } from 'react-icons/ri';
import { IoSettingsOutline, IoBookmarks, IoAlertCircleSharp, IoCheckbox } from 'react-icons/io5';
import { HiDotsCircleHorizontal } from 'react-icons/hi';
import { FaChevronCircleDown, FaExternalLinkAlt } from 'react-icons/fa';
import { MdWavingHand } from 'react-icons/md';
/**
 * React Icons --> https://react-icons.github.io/react-icons/
 */
export const Icon = {
  download: (props) => <BsFillCloudArrowDownFill {...props} />,
  search: (props) => <BsSearch {...props} />,
  edit: (props) => <BsFillPenFill {...props} />,
  remove: (props) => <BsFillTrashFill {...props} />,
  view: (props) => <BsBookHalf {...props} />,
  clear: (props) => <BsFillXCircleFill {...props} />,
  cancel: (props) => <BsFillXCircleFill {...props} />,
  logout: (props) => <RiLogoutBoxRFill {...props} />,
  login: (props) => <RiLoginBoxFill {...props} />,
  save: (props) => <BsSave2Fill {...props} />,
  print: (props) => <BsPrinterFill {...props} />,
  confirm: (props) => <BsFillCheckCircleFill {...props} />,
  forgetPassword: (props) => <BsLockFill {...props} />,
  back: (props) => <BsArrowLeftCircleFill {...props} />,
  create: (props) => <BsFillPlusCircleFill {...props} />,
  reAct: (props) => <BsArrowClockwise {...props} />,
  recheck: (props) => <BsShieldFillCheck {...props} />,
  register: (props) => <BsPersonCheckFill {...props} />,
  settings: (props) => <IoSettingsOutline {...props} />,
  threeDots: (props) => <HiDotsCircleHorizontal {...props} />,
  downCircle: (props) => <FaChevronCircleDown {...props} />,
  bookmark: (props) => <IoBookmarks {...props} />,
  alert: (props) => <IoAlertCircleSharp {...props} />,
  checkboxSquare: (props) => <IoCheckbox {...props} />,
  wavingHand: (props) => <MdWavingHand {...props} />,
  external: (props) => <FaExternalLinkAlt {...props} />,
  /** MENU ICON */
  dashboard: (props) => <BsKanban {...props} />,
  todo: (props) => <BsJournalBookmarkFill {...props} />,
  formExample: (props) => <BsLayoutSidebarInsetReverse {...props} />,
};
