import dayjs from 'dayjs';

export const convertYearInputToPayload = (date) => {
  if (!date) return '';
  return dayjs(date, 'DD/MM/YYYY').format('YYYY-MM-DD');
};

export const convertDateInputToPayload = (date) => {
  if (!date) return '';
  return dayjs(date, 'DD/MM/YYYY').format('YYYY-MM-DD');
};
