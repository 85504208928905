import React, { useEffect } from 'react';
import WrapperComponent from '../components/hoc/Wrapper-component';
import { useGetCustomer } from './hooks/useGetCustomer';
import { useDispatch, useSelector } from 'react-redux';
import { setInfo } from '../redux/info/action';
import { ButtonTheme } from '../components/buttons';
import { navigateTo } from '../utils/navigation';
import { ROUTES_PATH } from '../resources/routes-name';
import { setCookies } from '../store/useCookies';

export const IndexScene = () => {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.line.profile) || {};

  const { mutate: onCallInfo } = useGetCustomer();

  // useEffect(() => {
  // dispatch(loginLineRequest());
  // }, []);

  useEffect(() => {
    if (profile?.userId) {
      onCallInfo(
        { userId: profile.userId },
        {
          onSuccess: (info) => {
            dispatch(setInfo(info));

            setCookies('accessToken', info?.result.item.accessToken);
          },
        },
      );
    }
  }, [dispatch, profile, onCallInfo]);

  const onClick = () => {
    navigateTo({ pathname: ROUTES_PATH.ROUTE_REGISTER.PATH });
  };

  return (
    <div>
      <ButtonTheme useFor="SUBMIT" onClick={onClick} />
    </div>
  );
};

export default WrapperComponent('IndexScene')(IndexScene);
