import { useMutation } from '@tanstack/react-query';
import { POST, POST_SUPERMEMBER } from '../../../../services';
import { openAlert } from '../../../../components/alert/hooks';

export const useInsertSuper = () => {
  return useMutation({
    mutationFn: async (formData) => {
      const response = await POST(POST_SUPERMEMBER(), formData);

      return response.result;
    },
    onSuccess: () => {
      openAlert({
        model: 'message',
        type: 'success',
        message: 'ลงทะเบียน Super Member สำเร็จ',
      });
    },
  });
};
