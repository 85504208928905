import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { ContentStyle } from '../../styles';
import NotfoundScene from '../../../../screens/not-found';
import { ROUTES_PATH } from '../../../../resources/routes-name';
const ContentLayout = ({ getTheme, sidebarMode }) => {
  return (
    <ContentStyle style={{ ...getTheme.sidebar[sidebarMode] }}>
      <Switch>
        <Route
          exact
          key={ROUTES_PATH.ROUTE_MAIN.KEY}
          path={ROUTES_PATH.ROUTE_MAIN.PATH}
          component={ROUTES_PATH.ROUTE_MAIN.COMPONENT}
        />
        {Object.values(ROUTES_PATH).map((route) => (
          <Route exact key={route.KEY} path={route.PATH} component={route.COMPONENT} />
        ))}
        <Route path="*" key="NOT_FOUND" component={NotfoundScene} />
      </Switch>
    </ContentStyle>
  );
};

export default ContentLayout;
