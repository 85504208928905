import { useMutation } from '@tanstack/react-query';
import { GET, GET_VERIFY_NUMBER } from '@/services';
import { navigateTo } from '@/utils/navigation';
import { ROUTES_PATH } from '@/resources/routes-name';
import { openAlert } from '../../../../components/alert/hooks';

export const useVerifyNumber = ({ getValues, reset }) =>
  useMutation({
    mutationFn: async ({ warrantyNumberCode }) => {
      const response = await GET(GET_VERIFY_NUMBER(warrantyNumberCode));
      return response.result;
    },
    onSuccess: (result) => {
      const products = result.items[0]?.products;

      reset({
        ...getValues(),
        products,
        ...result,
      });
      setTimeout(() => {
        navigateTo({ pathname: ROUTES_PATH.ROUTE_REGISTER_INFO.PATH });
      }, 2000);
    },
    onError: (error) => {
      openAlert({ model: 'message', type: 'error', message: error.message });
    },
  });
