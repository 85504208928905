import { reducerBuilder } from '../../functions/redux';
import * as TYPE from './types';

const initialState = {
  users: [],
  loading: false,
};

export const authenReducer = reducerBuilder(initialState, (builder) =>
  builder
    .addCase(TYPE.AUTHEN_LOGIN_REQ, (state) => {
      state.loading = true;
    })
    .addCase(TYPE.AUTHEN_LOGIN_SUCCESS, (state, { users }) => {
      state.loading = false;
      state.users = users;
    })
    .addCase(TYPE.AUTHEN_LOGIN_FAIL, (state, { message }) => {
      state.loading = true;
      state.error = message;
    })
    .addCase(TYPE.AUTHEN_LOGOUT_REQ, (state) => {
      state.loading = true;
    })
    .addCase(TYPE.AUTHEN_LOGOUT_SUCCESS, (state) => {
      state.loading = false;
      state.users = [];
    })
    .addCase(TYPE.AUTHEN_LOGOUT_FAIL, (state, { message }) => {
      state.loading = false;
      state.error = message;
    })
    .addCase(TYPE.AUTHEN_LOGIN_GOOGLE_REQ, (state) => {
      state.loading = true;
    })
    .addCase(TYPE.AUTHEN_LOGIN_GOOGLE_SUCCESS, (state, { users }) => {
      state.loading = false;
      state.users = users;
    })
    .addCase(TYPE.AUTHEN_LOGOUT_FAIL, (state, { message }) => {
      state.loading = false;
      state.error = message;
    })
    .addCase(TYPE.AUTHEN_GET_PROFILE_REQ, (state) => {
      state.loading = true;
    })
    .addCase(TYPE.AUTHEN_GET_PROFILE_SUCCESS, (state, { users }) => {
      state.loading = false;
      state.users = users;
    })
    .addCase(TYPE.AUTHEN_GET_PROFILE_FAIL, (state, { message }) => {
      state.loading = false;
      state.error = message;
    }),
);
