import { SET_WARRANTY_DATA } from './types';

const initialState = {
  warranty: null,
  items: [],
};

export const warrantyReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_WARRANTY_DATA:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
