export const reducerBuilder = (initialState, builder) => {
  const objCaseFunction = {};

  const addCase = (type, manageFunction) => {
    objCaseFunction[type] = manageFunction;

    return { addCase };
  };

  const caseBuilder = {
    addCase,
  };

  builder(caseBuilder);
  return (state = initialState, action) => {
    if (objCaseFunction[action.type]) {
      objCaseFunction[action.type](...Object.values({ state, action }).map((e) => e));
    }

    return { ...state };
  };
};

export const actionBuilder = (type) => {
  return (payload = undefined) => ({
    type,
    payload,
  });
};
