import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setTitle } from '../../../redux/title/action';
import { RenderForm } from '../../../components/forms';
import { useForm } from 'react-hook-form';
import { FOMR_INPUT_REGISTER, FORM_SUPER_MEMBER_STORE } from './forms';
import { StyledContainer } from '../register-line/styles';
import { ContainerButton } from '../../../styles/global-style';
import { ButtonTheme } from '../../../components/buttons';
import { Checkbox, Spin } from 'antd';
import { channelBuyQuery } from '../../../services/react-query/optionMasterQuery';
import { useProductOptions } from './func/useProductOptions';
import { useModal } from '../../../components/modal/hooks/useModal';
import { SuperMember } from './modals/SuperMember';
import { useInsertWarranty } from './hooks/useInsertWarranty';
import { handleSubmitForm } from './func/submitHandler';

const termsStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: '16px',
  flexDirection: 'column',
};

const linkStyle = { marginLeft: '8px', color: '#1890ff' };

export const RegisterInfo = () => {
  const { control, setValue, watch, getValues, handleSubmit } = useForm();
  const { open: openSuper, onToggle: onToggleSuper } = useModal();
  const dispatch = useDispatch();

  const [isAccepted, setIsAccepted] = useState(false);
  const [, setIsSubmitting] = useState(false);
  const { warranty } = useSelector((state) => state.warranty) || {};
  const { result } = useSelector((state) => state.info) || '';

  const dataInfo = result?.item;

  const productId = watch('productId');
  const mobileBrandId = watch('mobileBrandId');

  const { data: channelBuyList, isLoading: channelBuyLoading } = channelBuyQuery();
  const { mutate: mutateUpload } = useInsertWarranty();

  const { productOptions, brandOptions, modelOptions } = useProductOptions(
    productId,
    mobileBrandId,
  );

  useEffect(() => {
    dispatch(setTitle('ลงทะเบียนรับประกันสินค้า'));
  }, [dispatch]);

  useEffect(() => {
    if (warranty) {
      setValue('warrantyNumberCode', warranty?.warrantyNumberCode);
    }
    if (dataInfo) {
      setValue('fullName', dataInfo?.fullName);
      setValue('telephone', dataInfo?.telephone);
    }

    if (productOptions.length === 1) {
      setValue('productId', productOptions[0]?.value);
    }

    if (brandOptions.length === 1) {
      setValue('mobileBrandId', brandOptions[0]?.value);
    }

    if (modelOptions.length === 1) {
      setValue('mobileModelId', modelOptions[0]?.value);
    }
  }, [warranty, dataInfo, setValue, productOptions, brandOptions, modelOptions]);

  const handleCheckboxChange = (e) => setIsAccepted(e.target.checked);

  const onSubmit = (values) => handleSubmitForm(values, setIsSubmitting, mutateUpload);

  return (
    <Spin spinning={channelBuyLoading}>
      <StyledContainer>
        <RenderForm
          control={control}
          setValue={setValue}
          getValues={getValues}
          forms={FOMR_INPUT_REGISTER({
            channelBuyList,
            productOptions,
            brandOptions,
            modelOptions,
          })}
        />
        <ContainerButton center>
          <ButtonTheme useFor="SUPERMEMBER" onClick={onToggleSuper} />
          {openSuper && <SuperMember open={openSuper} onToggle={onToggleSuper} />}
        </ContainerButton>
        <RenderForm
          control={control}
          setValue={setValue}
          getValues={getValues}
          forms={FORM_SUPER_MEMBER_STORE()}
        />
        <div style={termsStyle}>
          <Checkbox checked={isAccepted} onChange={handleCheckboxChange}>
            ฉันได้ยอมรับ
          </Checkbox>
          <a
            href="/terms-and-conditions"
            target="_blank"
            rel="noopener noreferrer"
            style={linkStyle}
          >
            ข้อตกลงการรับประกันสินค้า
          </a>
          <ContainerButton center>
            <ButtonTheme
              useFor="REGISTER_WARRANTY"
              disabled={!isAccepted}
              onClick={handleSubmit(onSubmit)}
            />
          </ContainerButton>
        </div>
      </StyledContainer>
    </Spin>
  );
};
