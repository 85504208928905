import { useQuery } from '@tanstack/react-query';
import { GET, GET_CHANNEL_BUY, GET_PROMOTION } from '..';
import { serviceOptions } from '../../functions/serviceOptions';

export const channelBuyQuery = () =>
  useQuery({
    queryKey: ['channelBuyQuery'],
    queryFn: async () => {
      const response = await GET(GET_CHANNEL_BUY());
      return serviceOptions('CHANNEL_BUY', response.result.items);
    },
  });

export const promotionQuery = () =>
  useQuery({
    queryKey: ['promotionQuery'],
    queryFn: async () => {
      const response = await GET(GET_PROMOTION());
      return serviceOptions('PROMOTION', response.result);
    },
  });
