import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { serviceOptions } from '../../../../functions/serviceOptions';

export const useProductOptions = (productId, mobileBrandId) => {
  const { items } = useSelector((state) => state.warranty) || {};
  const [productOptions, setProductOptions] = useState([]);

  useEffect(() => {
    const products = items[0]?.products || [];
    setProductOptions(serviceOptions('PRODUCTS', products));
  }, [items]);

  const brandOptions = useMemo(() => {
    const selectedProduct = items[0]?.products?.find((el) => el?.productId === productId);
    return productId ? serviceOptions('MOBILE_BRAND', selectedProduct?.mobilebrands || []) : [];
  }, [productId, items]);

  const modelOptions = useMemo(() => {
    const selectedBrand = brandOptions.find((el) => el?.value === mobileBrandId);
    return productId ? serviceOptions('MOBILE_MODEL', selectedBrand?.mobileModels) : [];
  }, [mobileBrandId, brandOptions]);

  return { productOptions, brandOptions, modelOptions };
};
