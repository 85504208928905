import React from 'react';
import Pages from '../screens/all-page';
import { Icon } from './icon';

export const ROUTE_LOGIN = 'https://central.zetta-system.com';
export const ROUTE_LOGIN_MANUAL = '/account/login';
export const ROUTE_HANDLE = '/account/handle';

export const KEY_ROUTE_MAIN = '@ROUTES/ROUTE_MAIN';
export const KEY_ROUTE_HOME = '@ROUTES/ROUTE_HOME';
export const KEY_ROUTE_DASHBOARD = '@ROUTES/ROUTE_DASHBOARD';
export const KEY_ROUTE_TO_DO_LIST = '@ROUTES/ROUTE_TO_DO_LIST';
export const KEY_ROUTE_REGISTER = '@ROUTES/ROUTE_REGISTER';
export const KEY_ROUTE_REGISTER_INFO = '@ROUTES/ROUTE_REGISTER_INFO';

function renderStyle(comp) {
  return <div style={{ marginRight: '1px' }}>{comp}</div>;
}

export const ROUTES_PATH = {
  ROUTE_MAIN: {
    KEY: KEY_ROUTE_MAIN,
    PATH: '/',
    LABEL: 'หน้าหลัก',
    COMPONENT: Pages.IndexScene,
  },
  ROUTE_HOME: {
    KEY: KEY_ROUTE_HOME,
    PATH: '/home',
    LABEL: 'หน้าหลัก',
  },
  ROUTE_REGISTER: {
    KEY: KEY_ROUTE_REGISTER,
    ICON: renderStyle(<Icon.dashboard />),
    PATH: '/warranty',
    LABEL: 'สมัครสมาชิก',
    COMPONENT: Pages.Warranty,
  },
  ROUTE_REGISTER_INFO: {
    KEY: KEY_ROUTE_REGISTER_INFO,
    ICON: renderStyle(<Icon.dashboard />),
    PATH: '/register-info',
    LABEL: 'register-info',
    COMPONENT: Pages.RegisterInfo,
  },
};

function getKeyLowerCase(obj) {
  return Object.fromEntries(Object.entries(obj).map(([k, v]) => [k.toLowerCase(), v]));
}

export const ROUTES_MENU = [
  getKeyLowerCase(ROUTES_PATH.ROUTE_MAIN),
  getKeyLowerCase(ROUTES_PATH.ROUTE_REGISTER),
];
