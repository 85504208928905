import { useDispatch } from 'react-redux';
import { loginGoogle } from './../../../redux/authen/action';

export const useLogin = () => {
  const dispatch = useDispatch();

  const dispatchLogin = (auth) => {
    dispatch(loginGoogle(auth));
  };

  return {
    dispatchLogin,
  };
};
