import liff from '@line/liff';
import { call, put } from 'redux-saga/effects';
import { loginLineFail, loginLineSuccess } from './action';
import { setCookies } from '../../store/useCookies';
import { env } from '../../utils/env/config';

export function* loginLineSaga() {
  try {
    yield call([liff, liff.init], { liffId: env.line_liff_id });

    if (!liff.isLoggedIn()) {
      return liff.login();
    }

    const userProfile = yield call([liff, liff.getProfile]);
    yield call(setCookies, 'userId', userProfile.userId);
    yield call(setCookies, 'displayName', userProfile.displayName);
    yield call(setCookies, 'pictureUrl', userProfile.pictureUrl);

    yield put(loginLineSuccess({ userProfile }));
  } catch (error) {
    yield put(loginLineFail('LIFF initialization failed. Please try again later.'));
  }
}
