export const serviceOptions = (key, options = []) => {
  switch (key) {
    case 'CHANNEL_BUY':
      return options?.map((el) => ({ label: el.channelBuyNameTh, value: el.channelBuyId }));

    case 'PRODUCTS':
      return options?.map((el) => ({ label: el.productNameTh, value: el.productId }));

    case 'MOBILE_BRAND':
      return options?.map((brand) => ({
        label: brand.mobileBrandNameTh,
        value: brand.mobileBrandId,
        mobileModels: brand.mobileModels,
      }));

    case 'MOBILE_MODEL':
      return options?.map((model) => ({
        label: model.mobileModelNameTh,
        value: model.mobileModelId,
      }));

    case 'PROMOTION':
      return options?.map((el) => ({ label: el.promotionName, value: el.promotionId }));
  }
};
