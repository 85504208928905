import { useMutation } from '@tanstack/react-query';
import { GET, GET_CUSTOMER_LINE } from '../../services';

export const useGetCustomer = () => {
  return useMutation({
    mutationFn: async ({ userId }) => {
      const response = await GET(GET_CUSTOMER_LINE(userId));
      return response;
    },
  });
};
