import { useEffect } from 'react';
import { logout, login } from '../redux/authen/action';
import { ROUTES_PATH, ROUTE_LOGIN } from '../resources/routes-name';
import { navigateTo, windowNavigateReplaceTo } from '../utils/navigation';
import { useDispatch } from 'react-redux';
import { getCookies } from '../store/useCookies';

export const useAuthEffect = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    //
  }, [dispatch]);
};

export const useAuthLogin = () => {
  const dispatch = useDispatch();

  function toLogin() {
    windowNavigateReplaceTo({ pathname: ROUTE_LOGIN });
  }

  const onLogin = async (values) => {
    dispatch(login(values));
  };

  function checkLoginToken() {
    // if (!getCookies('accessToken')) { // open this and comment below
    if (getCookies('accessToken')) {
      dispatch(logout());
    }
  }

  function redirectToMain() {
    // if (getCookies('accessToken')) { // open this and comment below
    if (!getCookies('accessToken')) {
      navigateTo({ pathname: ROUTES_PATH.ROUTE_MAIN.PATH });
    }
  }

  return {
    toLogin,
    checkLoginToken,
    redirectToMain,
    onLogin,
  };
};
