import { combineReducers } from 'redux';
import { authenReducer } from './authen/reducer';
import { alertReducer } from './alert/reducer';
import { authenLineReducer } from './line/reducer';
import { titleReducer } from './title/reducer';
import { infoReducer } from './info/reducer';
import { warrantyReducer } from './warranty/reducer';

const rootReducer = combineReducers({
  authen: authenReducer,
  alert: alertReducer,
  line: authenLineReducer,
  title: titleReducer,
  info: infoReducer,
  warranty: warrantyReducer,
});

export default rootReducer;
