import WrapperComponent from '../components/hoc/Wrapper-component';
import { Warranty } from './registration/register-line/Register';
import { IndexScene } from '../screens/index';
import { RegisterInfo } from './registration/registration-info/RegisterInfo';

export default {
  Warranty: WrapperComponent('Warranty')(Warranty),
  IndexScene: WrapperComponent('IndexScene')(IndexScene),
  RegisterInfo: WrapperComponent('RegisterInfo')(RegisterInfo),
};
