import { Modal } from 'antd';
import React from 'react';

export const ModalTheme = (props) => {
  const { open, title, onToggle, children } = props;
  return (
    <Modal
      title={title || 'Basic Modal'}
      open={open}
      onOk={onToggle}
      onCancel={onToggle}
      maskClosable={false}
      destroyOnClose={true}
      footer={null}
    >
      {children}
    </Modal>
  );
};
